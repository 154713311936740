import React from 'react'
import { Link, graphql } from 'gatsby'
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TwitterShareButton,
  TwitterIcon,
  PinterestShareButton,
  PinterestIcon,
  RedditShareButton,
  RedditIcon,
  EmailShareButton,
  EmailIcon,
} from 'react-share'
import Confetti from 'react-dom-confetti'
import LimitedPosts from '../components/LimitedPosts'


import Layout from '../components/Layout'
import PinkCard from '../components/PinkCard'
import EmailListForm from '../components/EmailListForm'

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confetti: false
    }
  }
  render() {
    const { data } = this.props
    const content = data.content
    const { edges: posts } = data.latest
    
    return (
      <Layout color="pink"> 
        <section className="section">
          <div className="container">
            <PinkCard>
              <div className='card-text'>
                <h1 className='intro-title'>{content.frontmatter.foldTitle}</h1>
                <div style={{fontSize: '1.2rem'}} dangerouslySetInnerHTML={{__html: content.html}} />
              </div>
              <div>
                <div className='card-img' style={{background: `url(${content.frontmatter.foldImg})`, backgroundSize: 'cover', backgroundPosition: '50%, 50%'}} />
              </div>
            </PinkCard>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1 className="has-text-weight-bold is-size-2" style={{marginBottom: 24, color: '#606161', marginLeft: 18}}>Featured</h1>
            </div>
            <div className="content featured-wrapper" style={{display: 'flex'}}>
              <Link 
                className="featured first-feature mobile-full-width" 
                style={{marginRight: 18, background: `url(${content.fields.featured1.frontmatter.image})`, width: '50%', height: 730, backgroundPosition: '50% 50%', backgroundSize: 'cover', paddingTop: 18}} 
                to={content.fields.featured1.fields.slug}>
                <div className="featured-bubble"><div className='bubble-text'><h4 style={{color: '#FFF'}} >{content.fields.featured1.frontmatter.title}</h4></div></div>
              </Link>
              <div className='mobile-full-width' style={{width: '50%', height: 730, display: 'flex', flexDirection: 'column'}}>
                <Link 
                  className="featured" 
                  style={{marginBottom: 9, background: `url(${content.fields.featured2.frontmatter.image})`, width: '100%', height: 365, backgroundPosition: '50% 50%', backgroundSize: 'cover', paddingTop: 18}} 
                  to={content.fields.featured2.fields.slug}>
                  <div className="featured-bubble"><div className='bubble-text'><h4 style={{color: '#FFF'}} >{content.fields.featured2.frontmatter.title}</h4></div></div>
                </Link>
                <Link 
                  className="featured" 
                  style={{marginTop: 9, background: `url(${content.fields.featured3.frontmatter.image})`, width: '100%', height: 365, backgroundPosition: '50% 50%', backgroundSize: 'cover', paddingTop: 18}} 
                  to={content.fields.featured3.fields.slug}>
                  <div className="featured-bubble"><div className='bubble-text'><h4 style={{color: '#FFF'}} >{content.fields.featured3.frontmatter.title}</h4></div></div>
                </Link>
              </div>
            </div>
          </div>
          <div className="container" style={{marginTop: 64}}>
            <PinkCard>
              <div style={{textAlign: 'center', width: '100%'}}>
                <h1 className='intro-title' style={{marginBottom: 9}}>Check out Sarah's Etsy Shop!</h1>
                <p style={{fontSize: '1.2rem', marginBottom: 24}}>All sorts of crafts and spunky items for you, or the perfect gift for someone special!</p>
                <a className='unbutton' style={{cursor: 'pointer'}} href='https://www.etsy.com/shop/SarahGrandDesigns' target="_blank" rel="noopener noreferrer">
                  <div style={{width: '100%', transform: 'translateX(50%)'}}>
                    <Confetti 
                      active={this.state.confetti} 
                      config={{
                        angle: 45,
                        spread: 360,
                        width: '10px',
                        height: '10px',
                        elementCount: 500,
                        stagger: 10,
                        dragFriction: 0.22,
                        colors: ['#ffffff', '#ebcae1', '#ee5da1']
                      }} />
                  </div>
                  <button onMouseEnter={() => this.setState({confetti: true})} onMouseLeave={() => this.setState({confetti: false})} style={{border: 'none', background: '#FFF', cursor: 'pointer'}}>
                    Go to the shop
                  </button>
                </a>
              </div>
            </PinkCard>
            <div className="content">
            </div>
            <div className="content latest-wrapper">
              <div className="columns">
                <div className="column is-10 is-offset-1">
                  <br/>
                  <h1 className="has-text-weight-bold is-size-2"  style={{marginBottom: 0, color: '#606161'}}>The Latest</h1>
                  {posts.map(({ node: post }) => (
                    <div 
                      className="latest"
                      key={post.id}
                      style={{color: '#606161', paddingTop: 24}}
                    >
                      <Link to={post.fields.slug}>
                        <h2 style={{color: '#606161'}}>{post.frontmatter.title}</h2>
                        <div style={{color: '#606161', fontSize: '112%'}} dangerouslySetInnerHTML={{__html: post.excerpt}} />
                      </Link>  
                      <br/>
                      <Link to={post.fields.slug}>
                        <img src={post.frontmatter.image} width='100%' alt={post.frontmatter.title} />
                      </Link>
                      <br/>
                      <div className='post-links'>
                        <Link className='pink-link' to={post.fields.slug}>
                            Continue Reading →
                        </Link>
                        <div style={{display: 'flex', flexDirection: 'row', marginBottom: 18}}>
                          <FacebookShareButton style={{marginRight: 9}} url={`https://www.sarahgrand.com${post.fields.slug}`}><FacebookIcon size={18} round/></FacebookShareButton>
                          <LinkedinShareButton style={{marginRight: 9}} url={`https://www.sarahgrand.com${post.fields.slug}`}><LinkedinIcon size={18} round/></LinkedinShareButton>
                          <TwitterShareButton style={{marginRight: 9}} url={`https://www.sarahgrand.com${post.fields.slug}`}><TwitterIcon size={18} round/></TwitterShareButton>
                          <PinterestShareButton style={{marginRight: 9}} url={`https://www.sarahgrand.com${post.fields.slug}`}><PinterestIcon size={18} round/></PinterestShareButton>
                          <RedditShareButton style={{marginRight: 9}} url={`https://www.sarahgrand.com${post.fields.slug}`}><RedditIcon size={18} round/></RedditShareButton>
                          <EmailShareButton style={{marginRight: 9}} url={`https://www.sarahgrand.com${post.fields.slug}`}><EmailIcon size={18} round/></EmailShareButton>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <PinkCard>
              <div style={{width: '100%', textAlign: 'center'}}>
                <h1 className='intro-title' style={{marginBottom: 9, color: '#FFF'}}>Stay Inspired!</h1>
                <p style={{fontSize: '1.2rem', marginBottom: 24}}>Sign up to get notified when I publish a new post and get 20% off your first order!</p>
                <EmailListForm />
              </div>
            </PinkCard>
            <div className='content'> 
              <h1 className="has-text-weight-bold is-size-2"  style={{marginBottom: 0, color: '#606161', marginTop: 72, marginLeft: 18}}>More Posts</h1>
              <LimitedPosts />
            </div>
          </div>
        </section>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query IndexQuery {
    latest: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] },
      filter: { frontmatter: { templateKey: { eq: "post" } }},
      limit: 3,
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          html
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MMMM DD, YYYY")
            image 
          }
        }
      }
    }
    content: markdownRemark(frontmatter: {templateKey: {eq: "index" }}) {
      id
      html
      fields {
        featured1 {
          id
          fields {
            slug
          }
          frontmatter {
            title
            image
          }
        }
        featured2 {
          id
          fields {
            slug
          }
          frontmatter {
            title
            image
          }
        }
        featured3 {
          id
          fields {
            slug
          }
          frontmatter {
            title
            image
          }
        }
      }
      frontmatter {
        templateKey
        title
        metaTitle
        metaDesc
        metaImg 
        foldTitle
        foldBody
        foldImg
        featuredTitle
        ctaTitle
        ctaSubtitle
        latestTitle
      }
    }
  }
`
